// Translation
// Migrated
<template lang="pug">
div
  component.media-with-text.position-relative.overflow-hidden.p-0.bg-transparent.col-12.mb-5.pb-3.pb-md-4(
    :is="allowFullscreen ? 'button' : 'div'"
    @click="allowFullscreen ? showMediaModal() : null"
  )
    .aspect-ratio__4-4.aspect-ratio-xl__21-9.aspect-ratio-xxl__26-9.aspect-ratio-sm__16-9.z-index-1.position-relative
      LazyMediaPlayer.w-100.h-100.top-0.overflow-hidden.media-with-text-media(
        v-if="media.mediatype === 'video'"
        :url="media.location"
        :type="media.type"
        thumbnail-class="object-cover position-absolute h-100 w-100"
        :custom-thumbnail="media.thumbnail"
        :aspect-ratio="false"
        just-preview
        play-button-class="pb-6"
        :alt="alt + ' video'"
      )
      LazyResponsiveImage.overflow-hidden.w-100.h-100.media-with-text-media.aspect-ratio__content(
        v-else
        :alt="alt"
        :image="media.location"
        :type="media.type"
        cover
      )

    .d-flex.flex-column.w-100.align-items-center.position-relative.media-with-text-content.z-index-10
      svg.position-absolute(height="200", width="800")
        circle(cy="400", cx="400", r="400", fill="white")
      .position-relative.text-center.text-black
        .media-with-text-title.d-flex.flex-column.align-items-center
          slot(name="above-title")

          h3.text-break-pre-line.mb-3.font-weight-semibold(:data-i18n="tTitle") {{ toUnixNewlines($t(tTitle)) }}
          .d-inline-flex.flex-row.align-items-center.mb-3
            hr
            span.mx-3(:data-i18n="tSubtitle") {{ $t(tSubtitle) }}
            hr

        slot(name="description")
        .h5.font-weight-normal.media-with-text-by(
          v-if="tEndingText"
          :data-i18n="tEndingText"
        ) {{ $t(tEndingText) }}

  LazyMediaModal(
    v-if="mediaModalVisible"
    :visible="mediaModalVisible"
    :media-list="[media]"
    size="lg"
    centered
    autoplay
    @on-hide="hideMediaModal"
  )
</template>

<script>
export default defineNuxtComponent({
  props: {
    /**
     * @prop {Array<{
     *   location: string
     *   type: 'cloudinary' | 'youtube'
     *   mediatype: 'image' | 'video'
     * }>}
     */
    media: {
      type: Object,
      required: true
    },

    tTitle: {
      type: String,
      default: ''
    },

    tSubtitle: {
      type: String,
      default: ''
    },

    tEndingText: {
      type: String,
      default: ''
    },

    alt: {
      type: String,
      default: 'Grouptravel banner'
    },

    allowFullscreen: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mediaModalVisible: false
    }
  },

  methods: {
    toUnixNewlines,

    showMediaModal () {
      this.mediaModalVisible = true
    },

    hideMediaModal () {
      this.mediaModalVisible = false
    }
  }
})
</script>

<style lang="scss">
$by-text-font-size: 1.25rem;

.media-with-text {
  &-media {
    // height: 400px;
    position: absolute !important;
  }

  &-content {
    margin-top: -60px;
  }

  &-title {
    * {
      font-family: $serif-font;
    }

    h3 {
      line-height: 40px;

      @media (max-width: $sm) {
        font-size: 1.75rem;
      }
    }

    hr {
      width: 50px;
      background: black;
    }
  }

  &-by {
    font-size: $by-text-font-size;
    font-family: $serif-font;
    font-style: italic;
  }

  svg {
    margin-top: -15px;
    transform: scaleY(1.4);

    @media (max-width: $sm) {
      margin-top: -60px;
      transform: scaleY(0.75) scaleX(0.65);
    }
  }
}
</style>
